import React, { useEffect, useState } from "react";
import { images } from "../Assets";
import HamburgerIcon from "../Assets/hamburgerIcon";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { rolesTransform } from "../Lib/utils";
import { Sheet, SheetTrigger, SheetContent } from "./ui/sheet";
import Sidebar from "./Sidebar";

function getTimeIn12HourFormatGMT1() {
  const date = new Date();
  const options = {
    timeZone: "Africa/Lagos",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}

export default function Navbar() {
  const { firstName, lastName, roleId } = useSelector(
    (state) => state.auth.data
  );
  const { availableRiders } = useSelector((state) => state?.location);
  const [currentTime, setCurrentTime] = useState("");
  console.log(availableRiders, "RIDERLOACTION");

  function SheetDemo({ children }) {
    return (
      <Sheet>
        <SheetTrigger asChild>
          <button className='p-2'>
            <HamburgerIcon />
          </button>
        </SheetTrigger>
        <SheetContent side='left' className='w-[300px] sm:w-[400px]'>
          <div>
            <Sidebar isSheet={true} />
          </div>
        </SheetContent>
      </Sheet>
    );
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getTimeIn12HourFormatGMT1());
    }, 60000);
    setCurrentTime(getTimeIn12HourFormatGMT1());

    return () => clearInterval(intervalId);
  }, []);

  return (
    <header className='z-10 py-4 bg-[#f5fef1] fixed w-full md:pl-72 left-0 h-[70px] flex items-center '>
      <div className='flex px-3 py-2 md:hidden justify-between items-center'>
        <Link to='/dashboard' className='w-[20%]'>
          <img
            src={images.nownow}
            className='w-full h-auto'
            alt='nownow rider'
          />
        </Link>
        <p className='text-xl text-primaryGreen font-bold'>{currentTime}</p>
        <SheetDemo>
          <HamburgerIcon />
        </SheetDemo>
      </div>

      <div className='px-10 w-full hidden md:flex md:justify-between items-center'>
        <p className='text-xl text-primaryGreen font-bold'>{currentTime}</p>
        <div>
          <p className='text-xl text-primaryGreen font-bold'>
            {availableRiders?.length > 0 &&
              `Online Riders: ${availableRiders?.length}`}
          </p>
        </div>
        <div className='flex flex-col'>
          <p className='text-black font-bold'>
            {firstName} {lastName}
          </p>
          <p className='text-primaryAsh text-sm'>{rolesTransform[roleId]}</p>
        </div>
      </div>
    </header>
  );
}

import React, { useState, useEffect } from "react";
import DataTable from "../../Components/ui/dataTable";
import { rolesTransform } from "../../Lib/utils";
import { Button } from "../../Components/ui/button";
import NavTabs from "../../Components/ui/tabs";
import { Modal, ModalContent, ModalTrigger } from "../../Components/ui/modal";
import InviteUser from "./InviteUser";
import { format, parseISO } from "date-fns";
import {
  useActivateAdminMutation,
  useDeactivateAdminMutation,
  useDeleteAdminMutation,
  useGetAllAdminQuery,
} from "./UserApi";
import Spinner from "../../Assets/spinner";
import { SkeletonTable } from "../../Components/ui/skeleton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../Components/ui/dropDownMenu";
import { images } from "../../Assets";
import toast from "react-hot-toast";
import EditUser from "./editUser";
import { ProtectedRoutes } from "../../config/protectedRoutes";

const Users = () => {
  const { data, isLoading: loading } = useGetAllAdminQuery("", {
    refectchOnFocus: true,
    refetchOnReconnect: true,
  });
  const [activateUser, { isLoading: activateLoading }] =
    useActivateAdminMutation();
  const [deactivateUser, { isLoading: deactivateLoading }] =
    useDeactivateAdminMutation();
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteAdminMutation();
  const tabs = [
    { name: "Pending Approvals", id: "pending" },
    { name: "Approved Users", id: "approved" },
  ];
  const [newData, setNewData] = useState([]);
  const [activeTab, setActiveTab] = useState("pending");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("Invite");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");

  const setModalType = (type, id) => {
    setId(id);
    setType(type);
    setOpen(true);
  };

  const handleDeleteUser = async (id) => {
    try {
      const data = await deleteUser(id).unwrap();
      if (data.statusCode === 200) {
        setOpen(false);
        toast.success("User Deleted", { id: "orderCan" });
      }
    } catch (error) {
      toast.error("Please try again", { id: "order err" });
    }
  };

  const handleActivateUser = async (id) => {
    try {
      const data = await activateUser(id).unwrap();
      if (data.statusCode === 200) {
        setOpen(false);
        toast.success("User Activated", { id: "orderCan" });
      }
    } catch (error) {
      toast.error("Please try again", { id: "order err" });
    }
  };

  const handleDeactivateUser = async (id) => {
    try {
      const data = await deactivateUser(id).unwrap();
      if (data.statusCode === 200) {
        setOpen(false);
        toast.success("User Deactivated", { id: "orderCan" });
      }
    } catch (error) {
      toast.error("Please try again", { id: "order err" });
    }
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => <div className=''>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "role",
      header: "Role",
      cell: ({ row }) => (
        <div className='f'>
          {rolesTransform[row.getValue("role")] || "No Role Yet"}
        </div>
      ),
    },
    {
      accessorKey: "dateAdded",
      header: "Date Added",
      cell: ({ row }) => (
        <div className=''>
          {format(parseISO(row.getValue("dateAdded")), "LLL dd, y")}
        </div>
      ),
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => (
        <div className='lowercase'>{row.getValue("email")}</div>
      ),
    },
    {
      accessorKey: "phoneNumber",
      header: "Phone Number",
      cell: ({ row }) => (
        <div className='lowercase'>{row.getValue("phoneNumber")}</div>
      ),
    },

    {
      accessorKey: "activated",
      header: "Status",
      cell: ({ row }) => {
        const details = row.original;
        if (details?.activated === true) {
          return (
            <Button className='w-full' asChild variant='successful'>
              <p>Active</p>
            </Button>
          );
        }
        if (details?.emailVerified === true && details?.activated === false) {
          return (
            <Button className='w-full' asChild variant='failed'>
              <p>Deactivated</p>
            </Button>
          );
        }
        if (details?.emailVerified === false && details?.activated === false) {
          return (
            <Button className='w-full' asChild variant='pending'>
              <p>Pending</p>
            </Button>
          );
        }
      },
    },
    {
      id: "action",
      cell: ({ row }) => {
        const details = row.original;
        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  variant='outline'
                  className='outline-none focus:outline-none focus-visible:outline-none px-4'
                >
                  <img src={images.threeDots} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='bg-white'>
                <DropdownMenuItem>
                  <Button
                    className='p-0 w-full text-primaryBlack hover:text-white hover:bg-primaryGreen'
                    variant='ghost'
                    onClick={() =>
                      setModalType(
                        details?.activated ? "Deactivate" : "Activate",
                        details?.id
                      )
                    }
                  >
                    {details?.activated ? "Deactivate" : "Activate"}
                  </Button>
                </DropdownMenuItem>
                {details?.activated && (
                  <DropdownMenuItem>
                    <Button
                      className='p-0 w-full outline-none text-primaryBlack hover:text-white hover:bg-primaryGreen'
                      variant='ghost'
                      onClick={() => {
                        setModalType("Edit", details?.id);
                        setEmail(details?.email);
                      }}
                    >
                      Edit Role
                    </Button>
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        );
      },
    },
    {
      id: "delete",
      cell: ({ row }) => {
        const details = row.original;
        return (
          <>
            <Button
              className='p-0 w-full text-primaryBlack hover:text-white hover:bg-primaryGreen'
              variant='outline'
              onClick={() => setModalType("Delete", details?.id)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    try {
      if (loading === false) {
        if (activeTab === "approved") {
          const activatedUsers = data?.filter(
            (user) =>
              user.activated === true ||
              (user.activated === false && user.emailVerified !== false)
          );
          setNewData(activatedUsers);
        } else {
          const activatedUsers = data?.filter(
            (user) => user.emailVerified === false
          );
          setNewData(activatedUsers);
        }
      }
    } catch (error) {}
  }, [loading, activeTab, data]);

  return (
    <div className='bg-white p-4'>
      <div className='flex flex-col gap-2 md:flex-row md:gap-0 items-center justify-between'>
        <NavTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className='w-full md:w-[30%]'
        />
        <Button onClick={() => setModalType("Invite")}>Invite User</Button>
      </div>
      <div className='mt-5 max-h-[500px] overflow-hidden hover:overflow-auto  max-w-full rounded-md border'>
        <div className='w-full'>
          {loading ? (
            <SkeletonTable />
          ) : (
            <DataTable
              columns={columns}
              data={newData}
              columnVisibility={{
                name: activeTab !== "pending",
                phoneNumber: activeTab !== "pending",
                delete: activeTab === "pending",
                action: activeTab !== "pending",
              }}
            />
          )}
        </div>
      </div>
      <Modal open={open} onOpenChange={setOpen}>
        <ModalContent name={`${type} User`}>
          {type === "Invite" && <InviteUser />}
          {type === "Edit" && <EditUser id={id} email={email} />}
          {type === "Delete" && (
            <div>
              <p className='text-center mb-4'>
                Are you sure you want to delete this User?
              </p>
              <div className='flex justify-between items-center bg-white w-[100%] '>
                <Button
                  variant='successful'
                  className='w-[30%]'
                  onClick={() => handleDeleteUser(id)}
                >
                  {deleteLoading ? <Spinner /> : "Yes"}
                </Button>
                <Button
                  variant='failed'
                  className='w-[30%]'
                  onClick={() => setOpen(false)}
                >
                  No
                </Button>
              </div>
            </div>
          )}
          {type === "Deactivate" && (
            <div>
              <p className='text-center mb-4'>
                Are you sure you want to Deactivate this User?
              </p>
              <div className='flex justify-between items-center bg-white w-[100%] '>
                <Button
                  variant='successful'
                  className='w-[30%]'
                  onClick={() => handleDeactivateUser(id)}
                >
                  {deactivateLoading ? <Spinner /> : "Yes"}
                </Button>
                <Button
                  variant='failed'
                  className='w-[30%]'
                  onClick={() => setOpen(false)}
                >
                  No
                </Button>
              </div>
            </div>
          )}
          {type === "Activate" && (
            <div>
              <p className='text-center mb-4'>
                Are you sure you want to Activate this User?
              </p>
              <div className='flex justify-between items-center bg-white w-[100%] '>
                <Button
                  variant='successful'
                  className='w-[30%]'
                  onClick={() => handleActivateUser(id)}
                >
                  {activateLoading ? <Spinner /> : "Yes"}
                </Button>
                <Button
                  variant='failed'
                  className='w-[30%]'
                  onClick={() => setOpen(false)}
                >
                  No
                </Button>
              </div>
            </div>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ProtectedRoutes(Users, [19]);

import React, { useEffect, useState } from "react";
import { images } from "../../Assets";
import { Button } from "../../Components/ui/button";
import { useParams } from "react-router-dom";
import RiderProfileIndex from "./RiderProfileIndex";
import RiderRides from "./RiderRides";
import Spinner from "../../Assets/spinner";
import clsx from "clsx";
import Goback from "../../Components/goBack";
import {
  useActivateRiderMutation,
  useApproveRiderMutation,
  useDeactivateRiderMutation,
  useGetIndividualRiderQuery,
  useRejectRiderMutation,
} from "./RiderApi";
import { SkeletonTable } from "../../Components/ui/skeleton";
import { Modal, ModalContent, ModalTrigger } from "../../Components/ui/modal";
import toast from "react-hot-toast";
import { ProtectedRoutes } from "../../config/protectedRoutes";
const RiderProfile = () => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetIndividualRiderQuery(id);
  const [approveRider, { isLoading: isLoadingApproved }] =
    useApproveRiderMutation();
  const [rejectRider, { isLoading: isLoadingReject }] =
    useRejectRiderMutation();
  const [deactivateRider, { isLoading: isLoadingDeactivate }] =
    useDeactivateRiderMutation();
  const [activateRider, { isLoading: isLoadingActivate }] =
    useActivateRiderMutation();

  const handleApproveRider = async (id) => {
    try {
      const { data } = await approveRider(id);
      if (data?.statusCode < 300) {
        refetch();
        setReason("");
        setOpen(false);
        toast.success("Rider Approved Successfuly", { id: "riderApproved" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRejectRider = async () => {
    const body = {
      id,
      body: {
        reviewerComment: reason,
      },
    };
    try {
      const { data } = await rejectRider(body);
      if (data?.statusCode < 300) {
        toast.success("Rider Rejected Successfuly", { id: "riderApproved" });
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeactivateRider = async (riderId) => {
    try {
      const { data } = await deactivateRider(riderId);
      if (data?.statusCode < 300) {
        toast.success("Rider Deactivated Successfuly", { id: "riderApproved" });
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleActivateRider = async (riderId) => {
    try {
      const { data } = await activateRider(riderId);
      if (data?.statusCode < 300) {
        toast.success("Rider Activated Successfuly", { id: "riderApproved" });
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='w-full h-full p-4 md:p-5'>
      <Goback currentRoute={"Riders Profile"} />
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <div className='w-full bg-white rounded-md p-2 border border-gray-300'>
            <div className='md:flex items-center justify-between'>
              <div className='md:flex gap-7 p-4 items-center'>
                <div className='md:flex-shrink-0'>
                  <img
                    src={
                      data?.data?.imageFilename
                        ? `${process.env.REACT_APP_BASE_URL}file/asset/${data?.data?.imageFilename}`
                        : "https://via.placeholder.com/150x150?text=NowNow"
                    }
                    className='rounded-md h-[150px] w-[150px] object-cover'
                    alt='nownow rider'
                  />
                </div>

                <div className='md:gap-20 mt-4 md:mt-0'>
                  <span className='text-primaryBlack font-medium text-3xl'>
                    {data?.data?.firstName} {data?.data?.lastName}
                  </span>

                  {/* <div className='flex gap-4 mt-2'>
                <img src={images.motocycle} className='' alt='nownow rider' />
                <p className='text-base font-normal text-primaryBlack'>
                  FKJ-254XA
                </p>
              </div> */}
                </div>
              </div>
              <div className='flex flex-col md:flex-row p-4 gap-2 md:gap-5 items-center'>
                {data?.data?.status === "approved" && data?.data?.activated && (
                  <Button
                    className='text-redOuter'
                    variant='failed'
                    onClick={() => handleDeactivateRider(id)}
                    disabled={isLoadingDeactivate}
                  >
                    {isLoadingDeactivate ? <Spinner /> : "Deactivate Rider"}
                  </Button>
                )}
                {data?.data?.status === "approved" &&
                  !data?.data?.activated && (
                    <Button
                      className='text-white'
                      variant='failed'
                      onClick={() => handleActivateRider(id)}
                      disabled={isLoadingActivate}
                    >
                      {isLoadingActivate ? <Spinner /> : "Activate Rider"}
                    </Button>
                  )}
                {data?.data?.status === "pending" && (
                  <>
                    <Button
                      variant='successful'
                      onClick={() => handleApproveRider(id)}
                      disabled={isLoadingApproved}
                    >
                      {isLoadingApproved ? <Spinner /> : "Approve"}
                    </Button>
                    <Button variant='outline' onClick={() => setOpen(true)}>
                      Reject
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <RiderProfileIndex data={data?.data} />
          <RiderRides id={id} />
        </>
      )}
      <Modal open={open} onOpenChange={setOpen}>
        <ModalContent name='Reject Rider?'>
          <div className='flex flex-col gap-1 justify-between my-4'>
            <label
              htmlFor='deliveryNote'
              className='text-xs font-medium text-gray-700'
            >
              Reason*
            </label>
            <textarea
              id='deliveryNote'
              className='focus:border-primaryGreen outline-none min-h-[80px] w-full rounded-md border border-[#C2C7D6] px-2 py-2 text-sm'
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setError("");
              }}
            />
            {error && <p className='text-xs text-red'>{error}</p>}
          </div>
          <div className='flex justify-between items-center bg-white w-[100%] '>
            <Button
              variant='successful'
              className='w-[30%]'
              onClick={() => {
                if (!reason) {
                  return setError("Please type a reason");
                }
                handleRejectRider(id);
              }}
            >
              {isLoadingReject ? <Spinner /> : "Yes"}
            </Button>
            <Button
              variant='failed'
              className='w-[30%]'
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ProtectedRoutes(RiderProfile, [16, 18, 19]);

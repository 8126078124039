import Dashboard from "../Pages/Dashboard";
import Orders from "../Pages/Orders";
import Accounts from "../Pages/Accounts";
import Riders from "../Pages/Riders";
import Users from "../Pages/Users";
import Audit from "../Pages/Audit";
import RiderProfile from "../Pages/Riders/RiderProfile";

export const roleBasedRoutes = {
  17: [
    // Customer Service
    {
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "dashboard",
      allowedRoles: [17],
    },
    {
      path: "/orders",
      element: Orders,
      label: "Orders",
      icon: "orders",
      allowedRoles: [17],
    },
  ],
  15: [
    // Account
    {
      path: "/accounts",
      element: Accounts,
      label: "Accounts",
      icon: "accounts",
      allowedRoles: [15],
    },
    {
      path: "/orders",
      element: Orders,
      label: "Orders",
      icon: "orders",
      allowedRoles: [15],
    },
  ],
  16: [
    // Logistics
    {
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "dashboard",
      allowedRoles: [16],
    },
    {
      path: "/riders",
      element: Riders,
      label: "Riders",
      icon: "riders",
      allowedRoles: [16],
    },
    {
      path: "/riders/profile/:id",
      element: RiderProfile,
      allowedRoles: [16],
    },
  ],
  18: [
    // Admin-Operations
    {
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "dashboard",
      allowedRoles: [18],
    },
    {
      path: "/orders",
      element: Orders,
      label: "Orders",
      icon: "orders",
      allowedRoles: [18],
    },
    {
      path: "/riders",
      element: Riders,
      label: "Riders",
      icon: "riders",
      allowedRoles: [18],
    },
    {
      path: "/riders/profile/:id",
      element: RiderProfile,
      allowedRoles: [18],
    },
  ],
  19: [
    // Super Admin
    {
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "dashboard",
      allowedRoles: [17, 16, 18, 19],
    },
    {
      path: "/orders",
      element: Orders,
      label: "Orders",
      icon: "orders",
      allowedRoles: [15, 17, 18, 19],
    },
    {
      path: "/accounts",
      element: Accounts,
      label: "Accounts",
      icon: "accounts",
      allowedRoles: [15, 19],
    },
    {
      path: "/riders",
      element: Riders,
      label: "Riders",
      icon: "riders",
      allowedRoles: [16, 18, 19],
    },
    {
      path: "/users",
      element: Users,
      label: "Users",
      icon: "users",
      allowedRoles: [19],
    },
    {
      path: "/audit",
      element: Audit,
      label: "Audit Logs",
      icon: "audit",
      allowedRoles: [19],
    },
    {
      path: "/riders/profile/:id",
      element: RiderProfile,
      allowedRoles: [16, 18, 19],
    },
  ],
};

export const routePerms = {
  "dashboard": [17, 16, 18, 19],
  "orders": [15, 17, 18, 19],
  "accounts": [15, 19],
  "riders": [16, 18, 19],
  "users": [19],
  "audit": [19],
  "riders/profile/:id": [16, 18, 19],
};

export const getRoutesForRole = (roleId) =>
  roleBasedRoutes[roleId] || roleBasedRoutes[19];

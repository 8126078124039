import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { Layout } from "../Components";
import Errorpage from "../Components/Errorpage";
import Orders from "../Pages/Orders";
import Dashboard from "../Pages/Dashboard";
import Accounts from "../Pages/Accounts";
import Riders from "../Pages/Riders";
import Users from "../Pages/Users";

import SignUp from "../Pages/Auth/SignUp";
import Audit from "../Pages/Audit";
import Test from "../Components/Test";
import Login from "../Pages/Auth/Login";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import ResetPassword from "../Pages/Auth/ResetPassword";
import OtpAuthentication from "../Pages/Auth/OTPAuthentication";
import ResetPasswordSuccess from "../Pages/Auth/ResetPasswordSuccess";
import RiderProfile from "../Pages/Riders/RiderProfile";

import { store } from "../store";
const role = store.getState()?.auth?.data?.roleId;
const getRoutesForRole = (roleId) => {
  switch (roleId) {
    case 17: // Customer Service
      return [
        { index: true, element: <Navigate to='/dashboard' replace={true} /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/orders", element: <Orders /> },
        { path: "*", element: <Navigate to='/dashboard' replace={true} /> },
      ];
    case 15: // Account
      return [
        { index: true, element: <Navigate to='/accounts' replace={true} /> },
        { path: "/accounts", element: <Accounts /> },
        { path: "/orders", element: <Orders /> },
        { path: "*", element: <Navigate to='/accounts' replace={true} /> },
      ];
    case 16: // Logistics
      return [
        { index: true, element: <Navigate to='/dashboard' replace={true} /> },
        { path: "/riders", element: <Riders /> },
        { path: "/riders/profile/:id", element: <RiderProfile /> },
        { path: "/dashboard", element: <Dashboard trackAlone={16} /> },
        { path: "*", element: <Navigate to='/dashboard' replace={true} /> },
      ];
    case 18: // Admin-Operations
      return [
        { index: true, element: <Navigate to='/dashboard' replace={true} /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/orders", element: <Orders /> },
        { path: "/riders", element: <Riders /> },
        { path: "/riders/profile/:id", element: <RiderProfile /> },
        { path: "*", element: <Navigate to='/dashboard' replace={true} /> },
      ];
    default: // Default: All routes
      return [
        { index: true, element: <Navigate to='/dashboard' replace={true} /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/orders", element: <Orders /> },
        { path: "/users", element: <Users /> },
        { path: "/accounts", element: <Accounts /> },
        { path: "/riders", element: <Riders /> },
        { path: "/audit", element: <Audit /> },
        { path: "/riders/profile/:id", element: <RiderProfile /> },
        { path: "/audit", element: <Audit /> },
        { path: "*", element: <Navigate to='/dashboard' replace={true} /> },
      ];
  }
};

const Authenticatedroutes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Errorpage />,
    children: [
      { index: true, element: <Navigate to='/dashboard' replace={true} /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/orders", element: <Orders /> },
      { path: "/users", element: <Users /> },
      { path: "/accounts", element: <Accounts /> },
      { path: "/riders", element: <Riders /> },
      { path: "/audit", element: <Audit /> },
      { path: "/riders/profile/:id", element: <RiderProfile /> },
      { path: "/audit", element: <Audit /> },
      { path: "*", element: <Navigate to='/dashboard' replace={true} /> },
    ],
  },
]);

const UnAuthenticatedRoutes = createBrowserRouter([
  { index: true, element: <Navigate to='/login' replace={true} /> },
  { path: "/login", element: <Login />, errorElement: <Errorpage /> },

  {
    path: "/new-admin/:token",
    element: <SignUp />,
    errorElement: <Errorpage />,
  },

  {
    path: "/new-admin/:token",
    element: <SignUp />,
    errorElement: <Errorpage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    errorElement: <Errorpage />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    errorElement: <Errorpage />,
  },
  {
    path: "/otp",
    element: <OtpAuthentication />,
    errorElement: <Errorpage />,
  },
  {
    path: "/reset-success",
    element: <ResetPasswordSuccess />,
    errorElement: <Errorpage />,
  },
  { path: "*", element: <Navigate to='/login' replace={true} /> },
]);

export { Authenticatedroutes, UnAuthenticatedRoutes };
